html,
body,
#root,
.App {
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin-right: 10px;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;
  height: 10vmin;

  background-color: #4d3e3d;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-main {
  flex: 1;
  background-color: #fff9db;
  overflow: scroll;
}

.App-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: calc(24px + 15vmin);

  background-color: #c7ab87;
  color: white;
  font-size: calc(24px + 1vmin);
}

.App-button {
  flex: 1;
  height: 100%;
  border: none;

  align-self: center;

  color: #4d3e3d;
  background-color: #c7ab87;

  font-size: calc(18px + 2vmin);
}

.App-button-active {
  font-weight: bold;
}

.App-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(10px + 5vmin);

  padding: 5px;

  background-color: #4d3e3d;
  color: white;
  font-size: calc(10px + 1vmin);
}

.App-footer-install-hint {
  font-size: 12px;
}

.App-footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.App-footer a {
  color: white;
}

.Slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;

  margin-top: 25px;
  font-weight: bold;
  overflow: hidden;
  font-size: 24px;
}

.Slider-label {
  flex: 1;
  text-align: left;
}

.Slider-input {
  flex: 1;
}

.Gauge-result {
  margin-top: 15px;
  font-size: 24px;
  font-weight: bold;
}

.Info-equation {
  margin-top: 15px;
  font-style: italic;
  font-weight: bold;
}

.instructions {
  margin-top: 10px;
  padding: 0 10px;
  text-align: left;
}

.Info-disclaimer {
  font-style: italic;
  font-size: 10px;
  margin-top: 20px;
  text-align: left;
}

.Info-resources {
  text-align: left;
  list-style: disc;
}

.BeatChart-method-name {
  margin-bottom: 0;
}

.BeatChart-learn-more {
  margin-top: 0;
  margin-bottom: 0;
}

.BeatChart-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;

  text-align: left;
  padding: 0 20px;
}

.BeatChart-option-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.BeatChart-option-container > label{
  margin-right: 10px;
}

.BeatChart-separator {
  margin: 10px 20px;
  border-bottom: 1px dashed black;
}

.BeatChart-support {
  font-size: 12px;
  text-align: justify;
  font-style: italic;
  margin: 5px 20px;
}

.BeatChart-beat-list {
  width: 100%;
  margin: auto;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input,
select {
  font-size: 18px;
}

@media all and (display-mode: standalone) {
  .App-footer {
    display: none;
  }
}

@media all and (min-height: 700px) {
  .App-buttons {
    height: calc(14px + 5vmin);
    font-size: calc(14px + 1vmin);
  }

  .App-button {
    font-size: calc(12px + 2vmin);
  }

  td {
    font-size: 24px;
  }
}

@media all and (max-width: 400px) {
  .BeatChart-options-container {
    flex-direction: column;
  }

 #beat-chart-breakdown  {
   max-width: 300px;
  }
}
